<template>
  <div class="content">
    <div class="member-search">
      <Row>
        <Col span="24">
          <h2 class="member-search-title border-bottom">
            <span class="member-search-text">按条件搜索</span>
          </h2>
        </Col>
      </Row>
      <div class="search-form" @keyup.enter="handleSearch">
        <Form :label-width="90" :model="searchData" ref="formValidate">
          <Row :gutter="16">
            <Col span="5">
              <Form-item label="咨询日期：">
                <Date-picker
                  @on-change="handleChangeDate"
                  type="daterange"
                  placeholder="选择日期"
                  style="width:100%;"
                ></Date-picker>
              </Form-item>
            </Col>
            <Col span="5">
              <Form-item label="顾客类型：">
                <Select clearable v-model="searchData.gklx" placeholder="请选择">
                  <Option value="卡系会员">卡系会员</Option>
                  <Option value="非卡系会员">非卡系会员</Option>
                </Select>
              </Form-item>
            </Col>
            <Col span="5">
              <Form-item label="服务员工：">
                <Select clearable filterable v-model="searchData.fwyg" placeholder="请选择">
                  <Option :value="v.name" v-for="v in staffList" :key="v.id">{{ v.name }}</Option>
                </Select>
              </Form-item>
            </Col>
            <Col span="5">
              <Form-item label="咨询类型：">
                <Select clearable v-model="searchData.zxlx" placeholder="请选择">
                  <Option value="ECP方案咨询">ECP方案咨询</Option>
                  <Option value="报告咨询">报告咨询</Option>
                </Select>
              </Form-item>
            </Col>
            <Col span="4">
              <Form-item label="报告名称：">
                <Select clearable v-model="searchData.bgmc" placeholder="请选择">
                  <Option
                    v-for="item in reportNameList"
                    :value="item.id"
                    :key="item.id"
                  >{{ item.name }}</Option>
                </Select>
              </Form-item>
            </Col>
          </Row>
          <Row :gutter="16" v-if="!branchShow">
            <Col span="5">
              <Form-item label="咨询结果：">
                <Select clearable multiple v-model="searchData.zxjg" placeholder="请选择">
                  <Option value="靶向产品预热">靶向产品预热</Option>
                  <Option value="靶向产品销售">靶向产品销售</Option>
                  <Option value="卡系消耗">卡系消耗</Option>
                </Select>
              </Form-item>
            </Col>
            <template v-if="!branchShow">
              <Col span="5">
                <Form-item label="省份：">
                  <Select
                    clearable
                    v-model="searchData.pid"
                    placeholder="请选择"
                    @on-change="changePro"
                  >
                    <Option :value="v.id" v-for="v in proList" :key="v.id">{{ v.name }}</Option>
                  </Select>
                </Form-item>
              </Col>
              <Col span="5">
                <Form-item label="城市：">
                  <Select
                    clearable
                    v-model="searchData.cid"
                    placeholder="请选择"
                    @on-change="changeCity"
                    @click.native="changeBossCity"
                  >
                    <Option :value="v.id" v-for="v in cityList" :key="v.id">{{ v.name }}</Option>
                  </Select>
                </Form-item>
              </Col>
              <Col span="5">
                <Form-item label="门店：">
                  <Select
                    clearable
                    v-model="searchData.sid"
                    placeholder="请选择"
                    @on-change="changeStore"
                    @click.native="changeBossBranch"
                  >
                    <Option :value="v.id" v-for="v in storeList" :key="v.id">{{ v.name }}</Option>
                  </Select>
                </Form-item>
              </Col>
              <Col span="4">
                <Form-item label="顾客：">
                  <Select
                    clearable
                    filterable
                    placeholder="请选择"
                    v-model="searchData.member_id"
                  >
                    <Option
                      v-for="item in userList"
                      :value="item.id"
                      :key="item.id"
                    >{{ item.true_name }}</Option>
                  </Select>
                </Form-item>
              </Col>
            </template>
            <template v-else>
              <Col span="5">
                <Form-item label="省份：">
                  <Input disabled v-model="proName"></Input>
                </Form-item>
              </Col>
              <Col span="5">
                <Form-item label="城市：">
                  <Input disabled v-model="cityName"></Input>
                </Form-item>
              </Col>
              <Col span="5">
                <Form-item label="门店：">
                  <Input disabled v-model="sName"></Input>
                </Form-item>
              </Col>
              <Col span="4">
                <Form-item label="顾客：">
                  <Select
                    clearable
                    placeholder="请选择"
                    v-model="searchData.member_id"
                  >
                    <Option
                      v-for="item in userList"
                      :value="item.id"
                      :key="item.id"
                    >{{ item.true_name }}</Option>
                  </Select>
                </Form-item>
              </Col>
            </template>
          </Row>
          <Row>
            <Col :lg="{ span: 12, offset: 12 }" :md="{ span: 12, offset: 12 }">
              <Button type="warning" @click="handleSearch">&nbsp;&nbsp;搜索&nbsp;&nbsp;</Button>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
    <div class="content-box">
      <h2 class="content-title border-bottom">
      <span>咨询列表</span>
      <div class="member-oprate">
          <a href="javascript:;" class="export" @click="exportData()">导出</a>
        </div>
      </h2>
      <div class="content-body">
        <Table :columns="columns" :data="resultData" ref="table"></Table>
        <Row>
          <Col span="12">
            <div class="table-oprate"></div>
          </Col>
          <Col span="12">
            <span class="records">共{{ pageTotal }}条记录</span>
          </Col>
        </Row>
        <div class="page-nations-wrapper">
          <div class="page-nations">
            <Page
              :total="pageTotal"
              :current="searchData.page"
              :page-size="searchData.size"
              @on-change="handleChangePage"
            ></Page>
          </div>
        </div>
      </div>
    </div>
    <Modal
      v-model="showModal"
      title="咨询评价"
      class-name="vertical-center-modal"
      width="900"
      :loading="loading"
      @on-visible-change="handleModalVisible"
      @on-ok="commentService"
    >
      <div class="search-box">
        <div class="search-row">
          <span class="sub-label">评价内容：</span>
          <div class="modal-sub-content">
            <Input v-model="modalData.pjnr" placeholder="请输入" type="textarea" />
          </div>
        </div>
        <div class="search-row">
          <span class="sub-label">评价打星：</span>
          <div class="modal-sub-content" style="height: 32px;line-height: 32px;">
            <Rate v-model="modalData.xj" :count="5"/>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script type="text/ecmascript-6">
import MemberService from '@/services/memberService';
import serviceRecordService from '@/services/serviceRecordService';
import comparisonService from '@/services/comparisonService';
import familyService from '@/services/familyService';

export default {
	data() {
		return {
			searchData: {
				pid: '',
				cid: '',
				sid: '',
				member_id: '',
				start: '',
				end: '',
				gklx: '',
				fwyg: '',
				zxlx: '',
				bgmc: '',
				zxjg: [],
				page: 1,
				size: 10,
			},
			isAdmin: '',
			staffList: [],
			proList: [],
			cityList: [],
			storeList: [],
			userList: [],
			columns: [
				{ title: '日期', key: 'rq', align: 'center' },
				{ title: '服务员工', key: 'staff', align: 'center' },
				{ title: '顾客名称', key: 'gkmc', align: 'center' },
				{ title: '咨询', key: 'zx', align: 'center' },
				{ title: '报告名称', key: 'bgmc', align: 'center' },
				{ title: '生理数值对比情况', key: 'slszdbqk', align: 'center' },
				{ title: '感受变化', key: 'gsbh', align: 'center' },
				{ title: '方案措施', key: 'facs', align: 'center', tooltip: true },
				{ title: '咨询结果', key: 'zxjg', align: 'center' },
				{ title: '下次预约时间', key: 'xcyysj', align: 'center' },
				{ title: '服务评价', key: 'pjnr', align: 'center', tooltip: true },
				{
					title: '评价打星',
					align: 'center',
					render: (h, params) => {
						return h('Rate', {
							attrs: {
								count: 5,
								value: params.row.xj ?? 0,
								'allow-half': true,
								disabled: true,
							},
						});
					},
				},
				{
					title: '操作',
					align: 'center',
					render: (h, params) => {
						const self = this;
						return h('div', [
							h(
								'Button',
								{
									attrs: {
										type: 'primary',
										size: 'small',
									},
									style: {
										marginRight: '5px',
									},
									on: {
										click() {
											self.handleComment(params.row.id);
										},
									},
								},
								'评价',
							),
							h(
								'Button',
								{
									attrs: {
										type: 'error',
										size: 'small',
									},
									on: {
										click() {
											self.handleDelete(params.row.id);
										},
									},
								},
								'删除',
							),
						]);
					},
				},
			],
			resultData: [],
			pageTotal: 0,
			branchShow: false,
			branch_id: [],
			power: [],
			proName: '',
			cityName: '',
			sName: '',
			reportNameList: [],
			showModal: false,
			loading: true,
			modalData: {
				pjnr: '',
				xj: 0,
				jl_id: '',
			},
		};
	},
	methods: {
		// 根据省份选择城市
		changePro() {
			this.cityList = this.city[this.searchData.pid];
			this.searchData.cid = '';
		},
		// 根据城市选择门店
		changeCity() {
			this.storeList = this.store[this.searchData.cid];
			this.searchData.sid = '';
		},
		// 根据门店选择用户
		changeStore() {
			if (this.searchData.sid) {
				const params = {
					page: 1,
					size: 1000,
					pid: this.searchData.pid,
					cid: this.searchData.cid,
					sid: this.searchData.sid,
				};
				comparisonService.getUserList(params).then((data) => {
					this.userList = data.list;
				});
			} else {
				this.userList = [];
			}
		},
		initData() {
			let list = JSON.parse(localStorage.getItem('userInfo'));
			MemberService.getBase().then((data) => {
				this.proList = data.branch.pro;
				this.city = data.branch.city;
				this.store = data.branch.store;
				this.power = list.user_info.power;
				this.memberTypeData = data.member_type;
				if (this.power.indexOf('服务管理--查看所属店面会员信息权限') === -1) {
					this.branchShow = false;
				} else {
					if (
						this.power.indexOf('服务管理--查看全国会员信息权限') === -1 &&
						this.power.indexOf('服务管理--查看所属区域会员信息权限') === -1
					) {
						this.branchShow = true;
						this.branch_id = list.user_info.branch;
						this.searchData.sid = this.branch_id[2];
						this.searchData.cid = this.branch_id[1];
						this.searchData.pid = this.branch_id[0];
						this.proName = list.user_info.pro_name;
						this.cityName = list.user_info.city_name;
						this.sName = list.user_info.branch_name;
						this.changeStore();
					}
				}
			});
			serviceRecordService.getAdvisoryReportName().then((data) => {
				this.reportNameList = data;
			});
		},
		getQuery() {
			serviceRecordService
				.getAdvisoryManagement({ ...this.searchData, zxjg: this.searchData.zxjg.join(',') })
				.then((data) => {
					this.resultData = data.list;
					this.pageTotal = data.row_size;
				});
		},
		handleSearch() {
			this.searchData.page = 1;
			this.getQuery();
		},
		exportData () {
          location.href = 'http://conlife.sskh.net:9928/api/zxcj/export_jl?start=' + this.searchData.start +
          '&end=' + this.searchData.end +
          '&pid=' + this.searchData.pid +
          '&cid=' + this.searchData.cid +
          '&sid=' + this.searchData.sid +
          '&member_id=' + this.searchData.member_id +
          '&zxlx=' + this.searchData.zxlx +
          '&fwyg=' + this.searchData.fwyg +
          '&gklx=' + this.searchData.gklx +
          '&bgmc=' + this.searchData.bgmc +
          '&zxjg=' + this.searchData.zxjg.join(',')
        },
		// 促进日期
		handleChangeDate(date) {
			this.searchData.start = date[0];
			this.searchData.end = date[1];
		},
		// 页码
		handleChangePage(page) {
			this.searchData.page = page;
			this.getQuery();
		},
		// 点击服务员工先选择门店
		handleChangeStaff() {
			if (!this.searchData.sid) {
				this.$Message.warning('请先选择门店');
			}
		},
		// 点击服务客服先选择服务部门
		handleChangeServiceCustomer() {
			if (!this.searchData.bm_id) {
				this.$Message.warning('请先选择服务部门');
			}
		},
		// 点击门店先选择城市
		changeBossBranch() {
			if (!this.searchData.cid) {
				this.$Message.warning('请先选择城市');
			}
		},
		// 点击城市先选择省份
		changeBossCity() {
			if (!this.searchData.pid) {
				this.$Message.warning('请先选择省份');
			}
		},
		handleModalVisible(visible) {
			if (!visible) {
				this.modalData = {
					pjnr: '',
					xj: 0,
				};
			}
		},
		handleComment(id) {
			this.showModal = true;
			this.modalData.jl_id = id;
		},
		handleDelete(id) {
			if (!this.isAdmin) {
				this.$Message.warning('你没有删除权限,请联系管理员');
				return;
			}
			this.$Modal.confirm({
				title: '确定删除所选记录吗？',
				onOk: () => {
					serviceRecordService.deleteService({ jl_id: id }).then(() => {
						this.getQuery();
					});
				},
			});
		},
		commentService() {
			serviceRecordService.commentService(this.modalData).then(() => {
				const target = this.resultData.find((item) => item.id === this.modalData.jl_id);
				target.xj = this.modalData.xj;
				target.pjnr = this.modalData.pjnr;
				this.$Message.success('评价成功');
				this.showModal = false;
			});
		},
		getAllUsers() {
			familyService.getAllUsers().then((data) => {
				this.userList = data.list;
			});
		},
	},
	mounted() {
		this.initData();
		this.getQuery();
		this.getAllUsers();
		this.isAdmin =
			JSON.parse(localStorage.getItem('loginInfo')).username === 'boss' ||
			JSON.parse(localStorage.getItem('loginInfo')).username === '陶正亮';
	},
	created() {
		MemberService.getServiceStaffList().then((data) => {
			this.staffList = data;
		});
	},
};
</script>

<style lang="less" scoped>
.service-manage-container {
  .member-search {
    background-color: #fff;
    margin-bottom: 20px;
  }
}
.search-form {
  padding: 20px 15px 30px 15px;
}
.search-form .ivu-input-number {
  width: 100%;
}
.member-list .member-title {
  margin-bottom: 16px;
}
.member-list .member-oprate {
  float: right;
  padding-right: 8px;
}
.card {
  margin: 0 20px 20px;
  &-title {
    background-color: #507fe0;
    font-size: 16px;
    color: #fff;
    padding: 5px 10px;
    display: inline-block;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    &_status {
      background-color: #fff;
      border: 1px #e9e9e9 solid;
      border-bottom: none;
    }
  }
  &-body {
    height: 220px;
    border: 1px #e9e9e9 solid;
    padding: 10px;
  }
  &-select {
    width: 50%;
  }
  &-textarea_left {
    margin-top: 10px;
  }
}
.header-nav {
  margin: 20px 0 0 16px;
  font-size: 16px;
  li {
    display: inline-block;
    margin-right: 20px;
    a {
      color: #000;
      &.active {
        color: #fdad00;
      }
    }
  }
}
.search-row {
  margin: 10px 0 0 10px;
}
.sub-label {
  float: left;
  vertical-align: middle;
  line-height: 32px;
}
.modal-sub-content {
  margin-left: 70px;
}
.member-oprate {
  float: right;
  font-size: 14px;
}

.member-oprate .export {
  background: url(@/assets/img/icon_export.png) no-repeat;
}
</style>
